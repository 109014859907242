import React from "react"
import { Parallax } from "react-parallax"
import { makeStyles, Typography, Button } from "@material-ui/core"
import Zoom from "react-reveal/Zoom"
import { AthSection } from "../layout"

export default function ParralaxSextion({ data }) {
  const useStyles = makeStyles((theme) => ({
    banner: {
      width: "100%",
      height: "100%",
      marginTop: "-2rem",
      minHeight:"50vh"
    },
    overlay: {
      width: "100%",
      height: "50vh",
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    content: {
      padding: "7rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      color: theme.palette.primary.white,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    ctaButton: {
      "& a": {
        transformText: "none",
        padding: "1rem 2rem",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
  }))
  const classes = useStyles()
  return (
    <AthSection>
      <Parallax
        className={classes.banner}
        bgImage={data.image.asset.fluid.src}
        strength={1000}
      >
        <div className={classes.overlay}></div>
        <Zoom>
          <div className={classes.content}>
            <Typography variant="h1">{data.heading}</Typography>
            <div className={classes.subtext}>
              <Typography variant="h4">{data.subtitle}</Typography>
            </div>
            <div className={classes.ctaButton}>
              <Button
                variant="contained"
                color="secondary"
                href={data.cta.route.slug.current
                  .split(" ")
                  .join("-")
                  .toLowerCase()}
              >
                <Typography variant="h5" component="span">
                  {data.cta.title}
                </Typography>
              </Button>
            </div>
          </div>
        </Zoom>
      </Parallax>
    </AthSection>
  )
}
