import React from "react"
import { Typography, Grid } from "@material-ui/core"
import { AthCard, AthSection } from "../layout"
import { makeStyles } from "@material-ui/core"
import Zoom from "react-reveal/Zoom"
import TextBlock from "../../lib/textblock"

export default function CardsSection({ data }) {
  const useStyles = makeStyles(theme => ({
    sectionContainer: {
      display: "grid",
      justifySelf: "center",
      alignSelf: "center",
      gridTemplateColumns: "repeat(4,1fr)",
      gridAutoRows: "max-content",
      gridGap: "1rem",
      padding: "0 5rem",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(2,1fr)",
        padding: "0 2rem",
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr 1fr",
        padding: "0 1rem",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        padding: 0,
      },
    },
    textContainer: {
      width: "100%",
      alignSelf: "center",
      justifySelf: "center",
      display:"grid",
      gridGap:"1.5rem",
    },
    message: {
      textAlign: "left",
      padding: "2rem 0",
      color: theme.palette.primary.fadedBlack,
    },
    subtitle: {
      color: theme.palette.primary.fadedBlack,
      margin:"1rem 0"
    },
  }))
  const classes = useStyles()
  return (
    <AthSection id={data.heading?data.heading.toLowerCase():""}>
      <Zoom>
        <Grid container justify="center" spacing={6}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.subtitle}
                  variant="h3"
                >
                  {data.subtext}
                </Typography>

                <Typography variant="h1" align="center">
                  {data.heading}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{textAlign:"center"}}>
                {data.message&&
                <TextBlock
                  text={data.message._rawText}
                />
}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              {data.cards.map((card, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <AthCard card={card} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Zoom>
    </AthSection>
  )
}
