import React, { useState, useCallback } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import Image from "gatsby-image"
import { Typography, Grid } from "@material-ui/core"
import { AthSection } from "../layout"
import { makeStyles } from "@material-ui/styles"
import Zoom from "react-reveal/Zoom"
export default function Gallery({ data }) {
  const fullSizeImages = []
  const useStyles = makeStyles((theme) => ({
    galleryContainer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridGap: "2rem",
      justifySelf: "center",
      [theme.breakpoints.down('xs')]:{
        marginBottom:theme.spacing(3)
      }
    },
    caption: {
      position: "absolute",
      top:0,
      padding:theme.spacing(1),
      transition: "transform 0.01s",
    },
    thumbnail: {
      cursor: "pointer",
      userSelect: "none",
      position: "relative",
      "& img": {
        transition: "all 0.5s ease-in-out !important",
      },
      "&:hover": {
        "& img": {
          transform: "scale3d(1.2,1.2,1.2)",
        },
      },
    },
    textBG: {
      width: "100%",
      height: "100px",
      position: "relative",
      display: "block",
      backgroundColor: [theme.palette.background.custom],
    },
  }))
  const classes = useStyles()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback(({ index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  data.fullWidth.forEach((image) => {
    fullSizeImages.push({
      source: image.asset.url,
    })
  })

  return (
    <AthSection>
      <div className={classes.galleryContainer}>
        
          <Typography align="center" variant="h1">
            {data.title}
          </Typography>
          <Typography variant="body1" align="center">
            {data.description}
          </Typography>
          <Grid container spacing={2}>
            {data.thumbnails.map((image, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
              <Zoom  key={index}>
              <div
                className={classes.thumbnail}
                role="button"
                onKeyDown={openLightbox}
               
                onClick={openLightbox}
                tabIndex={0}
              >
                <Image fluid={image.asset.fluid} alt={image.alt} />
                {image.caption && (
                  <>
                    <span className={classes.textBG}>
                      <div className={classes.caption}>
                        <Typography variant="h4">{image.caption}</Typography>
                      </div>
                    </span>
                  </>
                )}
              </div></Zoom></Grid>
            ))}
          </Grid>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={fullSizeImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </AthSection>
  )
}
