import React from "react"
import { AthSection } from "../layout"
import { Typography, Grid } from "@material-ui/core"
import Testimonial from "../layout/testimonial"
import { makeStyles } from "@material-ui/styles"
import Carousel from "react-slick"
import Zoom from "react-reveal/Zoom"
import "../../lib/style.css"
import CivilContractorsLogo from "../../images/civilContractorsLogo.svg"
import TextBlock from "../../lib/textblock"

const useStyles = makeStyles((theme) => ({
  icon: {
    "&>span": {
      "&:before": {
        fontSize: "70px",
      },
    },
  },
  subtext:{
    margin:"1rem 0"
  }
}))
export default function TestimonialSection({ data }) {
  const classes = useStyles()

  return (
    <AthSection id="testimonials">
      <Zoom>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.heading} variant="h1">
            Testimonials
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6} justify="center">
              <Grid item xs={12} md={6}>
                <Grid container justify="center" spacing={5}>
                  <Grid item xs={12}>
                    <Typography align="center" fontWeight="bold" variant="h2">
                      {data.heading}
                    </Typography>
                  </Grid>
                  {data.iconBlocks.map((iconBlock, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={2} justify="center">
                        <Grid
                          item
                          xs={12}
                          md={8}
                          style={{ backgroundColor: iconBlock.backgroundColor }}
                        >
                          <Grid
                            container
                            justify="center"
                            style={{ width: "100%" }}
                            spacing={2}
                            alignContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} md={6} className={classes.icon}>
                              {iconBlock.iconClass ? (
                                <span className={iconBlock.iconClass}></span>
                              ) : (
                                <CivilContractorsLogo />
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className={classes.text}>
                                <Typography variant="h4">
                                  {iconBlock.heading}
                                </Typography>
                                <Typography variant="h5">
                                  {iconBlock.message}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h2">{data.title}</Typography>
                <TextBlock text={data.message._rawText} />

                <Carousel
                  autoplay={true}
                  infinite={true}
                  showStatus={false}
                  className={classes.carousel}
                  showThumbs={false}
                  useCSS={true}
                  useTransform={true}
                  speed={1500}
                  dots={true}
                >
                  {data.testimonial.map((queue, index) => (
                    <div key={index} index={index}>
                      <Testimonial data={queue} />
                    </div>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Zoom>
    </AthSection>
  )
}
