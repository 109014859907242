import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { TextField } from "formik-material-ui"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import toast from 'cogo-toast'
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    height: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "1rem 5rem",
  },
  heading: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
}))

export default function ContactForm() {
  const classes = useStyles()
  const [status, setStatus] = useState(true)
  function submitForm(ev) {
    const { firstName, email, phone, lastName, message } = ev
    const textpart = `New Webform was Submitted, with these details Name: ${firstName} ${lastName} Phone: ${phone} Contact Email: ${email}. ${firstName} also sent this message ${message}. Kind Regards Webform worker`
    const htmlpart = `<h3>New Webform was Submitted,</h3><br />  Details are  Name: ${firstName} ${lastName}<br /> Phone: ${phone} <br /> Contact Email: ${email} <br />. ${firstName} also sent this message<br />" ${message} ".<br /> Kind Regards Webform worker`
    const data = {
      authID: "d63a50f1e641bb7fe527eca3a88d5eeb",
      message: { textpart: textpart, htmlpart: htmlpart },
      fromemail: "webform@chambersandjackett.co.nz",
      email: "admin@chambersandjackett.co.nz",
      name: "Web Enquiry",
    }
    const url = `https://ath.nz/athAPI?action=sendmail&data=${JSON.stringify(
      data
    )}`
    fetch(url)
      .then(data => {
        setStatus(false)
        toast.success("Your enquiry has been submitted!")
      })
      .catch(e => {
        setStatus(true)
        toast.error(e.message)
      })
  }
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  }
  return (
    <div className={classes.paper}>
      <Typography component="h5" variant="h3" className={classes.heading}>
        Contact Us
      </Typography>
      <div className={classes.form}>
        <Formik initialValues={initialValues} onSubmit={submitForm} validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}>
          <Form>
            <Grid container alignContent="center" alignItems="center" justify="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  required
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  required
                  variant="outlined"
                  fullWidth
                  id="phone"
                  label="Contact Phone"
                  name="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  required
                  fullWidth
                  name="message"
                  label="Message"
                  type="text"
                  id="message"
                  multiline
                  rows="5"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  className={classes.submit}
                  disabled={!status}
                >
                  <Typography variant="h5" component="span">
                    Submit
              </Typography>
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
