import React from "react"
import { AthSection } from "../layout"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Zoom from "react-reveal/Zoom"
import TextBlock from "../../lib/textblock"
const useStyles = makeStyles(theme => ({
  gridContainer: {
    position: "relative",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "max-content max-content auto",
    gridTemplateAreas: '"header" "content"',
  },
  textGrid: {
    gridArea: "text",
    display: "grid",
  },
  itemText: {
    position: "relative",
    gridArea: "content",
    lineBreak: "auto",
    padding: "1rem",
    textAlign: "left",
    justifySelf: "center",
  },
  itemHeader: {
    gridArea: "header",
  },
}))
export default function TextImageSection({ data }) {

  const classes = useStyles()
  return (
    <AthSection>
      <Zoom>
        <div className={classes.gridContainer}>
          <div className={classes.itemHeader}>
            <Typography
              style={{ padding: "1.5rem" }}
              variant="h4"
              align="center"
            >
              {data.label}
            </Typography>
            <Typography variant="h2" align="center">
              {data.heading}
            </Typography>
          </div>
          <div className={classes.itemText}>
            <TextBlock text={data.text._rawText}/>
          </div>
        </div>
      </Zoom>
    </AthSection>
  )
}
