import React from "react"
import { Typography, Grid, Box, Paper } from "@material-ui/core"
import Section from "../layout/section"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/styles"
const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor:"#ffffff",
  },
  headerContainer: {
    padding: "1.5rem 0",
    marginBottom: "1.5rem",
  },
  mobilepadding:{
    [theme.breakpoints.down('xs')]:{
      padding:`${theme.spacing(1,5)} !important`
    }
  }
}))
export default function ClientsSection({ data }) {
  const classes = useStyles()
  return (
    <Section id="clients">
    <Paper className={classes.bg}>
      <div className={classes.headerContainer}>
        <Typography variant="h3" align="center">
          Our Clients
        </Typography>
      </div>
      <Box mt={10} mb={6}>
        <Grid container justify="space-evenly" alignItems="center" spacing={2}>
          {data.clientLogos.map((logo, index) => (
            <Grid className={classes.mobilepadding} item key={index} xs={12} sm={6} md={3} lg={2}>
              <Image
                imgStyle={{ objectFit: "contain" }}
                fluid={logo.asset.fluid}
                alt={logo.alt}
              />
            </Grid>
          ))}
        </Grid>
      </Box></Paper>
    </Section>
  )
}
