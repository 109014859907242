import React from "react"
import { Typography } from "@material-ui/core"
import { AthSection } from "../layout"
import AthCard from "../layout/teamCard"
import { makeStyles, useTheme,useMediaQuery } from "@material-ui/core"
import Zoom from 'react-reveal/Zoom'
import TextBlock from "../../lib/textblock"
export default function TeamSection({ data }) {
  const useStyles = makeStyles(theme => ({
    sectionContainer: {
      display: "grid",
      gridTemplateColumns: "15vw repeat(4,1fr) 15vw",
      gridAutoRows: "max-content",
      gridGap: "1rem",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "2vw repeat(4,1fr) 2vw",
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down('xs')]:{
        gridTemplateColumns: "1fr",
      }
    },
    textContainer: {
      width: "100%",
      alignSelf: "center",
      justifySelf: "center",
      display:"grid",
      gridGap:"1.5rem",
      justifyItems:"center",
      alignItems:"center",
      justifyContent:"center",
      alignContent:"center",
      marginBottom:"1.5rem",
    },
    message: {
      textAlign: "left",
      padding: "2rem 0",
      color: theme.palette.primary.fadedBlack,
    },
    subtitle: {
      color: theme.palette.primary.fadedBlack,
      margin:"1rem,0"
    },
  }))
  const classes = useStyles()
  const theme= useTheme()
  const matches=useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <AthSection id="team">
      <div className={classes.sectionContainer}>
        <Zoom>
       <div
          className={classes.textContainer}
          style={{ gridColumn: "1/-1" }}
        >
          <Typography align="center" className={classes.subtitle} variant="h3">
            {data.subtext}
          </Typography>
          <Typography
          align="center"
            variant="h1"
          >
            {data.heading}
          </Typography>
          <TextBlock text={data.message._rawText}/>
        </div>
        {data.teamMembers.map((card, index) => (
          <div
            key={index}
            style={
             matches
                ?{padding:"1.5rem"}: { gridColumn: `${index + 2}/${index + 3}` }

            }
          >
            <AthCard key={index} card={card} />
          </div>
        ))}</Zoom>
      </div>
    </AthSection>
  )
}
