import React from "react"
import Slides from "../layout/slide"
import Carousel from "react-slick"
import { Parallax } from "react-scroll-parallax"

export default function Hero({ data }) {
  return (
    <div style={{height:"100%"}}>
    <Parallax y={[-50, 50]}>
      <Carousel
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        autoplay={true}
        useCSS={true}
        useTransform={true}
      >
        {data.slides.map((slide, index) => (
          <Slides key={index} data={slide} />
        ))}
      </Carousel>
    </Parallax></div>
  )
}
