import React from "react"
import { makeStyles, Typography, Button, useTheme, useMediaQuery } from "@material-ui/core"
import Fade from "react-reveal/Fade"
import TextBlock from "../../lib/textblock"
import Image from 'gatsby-image'

export default function Slide({ data }) {
const theme=useTheme()
const isPhone=useMediaQuery(theme.breakpoints.down('xs'))
  const useStyles = makeStyles((theme) => ({
    container: {
      position: "absolute",
      top:0,
      left:0,
      width:"100%",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
      [theme.breakpoints.down("md")]: {
        height: "96vh",
      },
    },
    bgOverlay: {
      width: "100%",
      height: "100%",
      backgroundColor: "#ffffff93",
      position: "absolute",
      left: "50%",
      [theme.breakpoints.down("xs")]: {
        left: 0,
      },
    },
    textContainer: {
      display: "grid",
      gridGap: "1rem",
      gridColumn: "1/-1",
      gridTemplateRows: "1fr min-content min-content 1fr",
      height: "100%",
      width: "100%",
      alignSelf: "center",
      justifySelf: "center",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "0rem",
      [theme.breakpoints.up("md")]: {
        gridColumn: "2/2",
        alignItems: "center",
        justifyItems: "center",
        paddingLeft: "6rem",
      },
    },
    button: {
      padding: "1rem 2rem",
    },
    justify: {
      justifySelf: "center",
      alignSelf: "end",
      textAlign: "center",
    },
    buttonText: {
      color: theme.palette.primary.white,
      textTransform: "none",
      fontWeight: "600",
    },
    subtext: {
      marginTop: "2.5rem",
      marginBottom: "1.5rem",
    },
    backgroundImage:{
      zIndex:0,
      height:"100vh",
      [theme.breakpoints.down('xs')]:{
        height:"100vh"
      }

    }
  }))
  const classes = useStyles()
  const link = data.cta && data.cta.route ? data.cta.route.slug.current : "/"

  return (
    <div style={{position:"relative",overflow:"hidden"}}>
    <div className={classes.backgroundImage}>
    <Image style={{height:"100%"}} loading="eager" fadeIn={false} fluid={isPhone?data.mobile.asset.fluid:data.desktop.asset.fluid}/>
    </div>
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <div className={classes.bgOverlay} />
        <div className={classes.justify} style={{ gridRow: "1" }}>
          <Fade bottom>
            <Typography variant="h1" component="span">
              {data.heading}
            </Typography>
          </Fade>
        </div>
        {data.tagline && (
          <div className={classes.justify} style={{ gridRow: "2" }}>
            <Fade bottom>
              <TextBlock text={data.tagline._rawText} />
            </Fade>
          </div>
        )}
        <div className={classes.justify} style={{ gridRow: "3" }}>
          <Fade bottom>
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              color="secondary"
              href={link}
            >
              <Typography
                className={classes.buttonText}
                variant="h5"
                component="span"
              >
                {data.cta.title}
              </Typography>
            </Button>
          </Fade>
        </div>
      </div>
    </div></div>
  )
}
