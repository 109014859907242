import React from "react"
import { AthSection } from "../layout"
import { Typography, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import Image from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import { AthCta } from "../layout"
import TextBlock from "../../lib/textblock"

export default function TextImageSection({ data }) {
  const useStyles = makeStyles((theme) => ({
    gridContainer: {
      position: "relative",
      display: "grid",
      gridGap: "1rem",

      gridTemplateRows: "max-content max-content max-content",
      gridTemplateAreas: `"header" "image" "text"`,
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(2,1fr)",
        gridTemplateAreas: `"text image"`,
      },
    },
    gridContainerFlip: {
      display: "grid",
      gridGap: "1rem",
      gridTemplateRows: "max-content max-content max-content",
      gridTemplateAreas: `"header" "image" "text"`,
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(2,1fr)",
        gridTemplateAreas: `"image text"`,
      },
    },
    textGrid: {
      gridArea: "text",
      display: "grid",
      alignContent: "space-between",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "max-content max-content auto",
      gridTemplateAreas: '"header" "content" "button"',
    },
    itemImage: {
      gridArea: "image",
      justifySelf: "stretch",
      alignSelf: "stretch",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr 1fr",
      justifyItems: "end",
      gridGap: "1rem",
    },
    itemText: {
      position: "relative",
      gridArea: "content",
      lineBreak: "auto",
      padding: "1rem",
      textAlign: "left",
      justifySelf: "center",
    },
    itemHeader: {
      gridArea: "header",
    },
    itemButton: {
      gridArea: "button",
      alignSelf: "center",
      justifySelf: "center",
    },
  }))

  const classes = useStyles()
  const theme=useTheme()
  const isPhone=useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <AthSection>
      <Zoom>
        <div
          className={
            data.imagePosition === "right"
              ? classes.gridContainer
              : classes.gridContainerFlip
          }
        >
          {isPhone&&
          <div className={classes.itemHeader}>
          <Typography align="center" variant="h2">
            {data.heading}
          </Typography>
          <Typography variant="h4" align="center">
            {data.label}
          </Typography>
        </div>
          }
          <div className={classes.itemImage}>
            {data.images.length > 0 && (
              <>
                {data.images.map((image, index) => (
                  <>
                    {image.asset && (
                      <div
                        style={{
                          gridRow:
                            image.asset.fluid.aspectRatio < 1 ? "1/-1" : "auto",
                          width:
                            image.asset.fluid.aspectRatio < 1
                              ? `${
                                  image.asset.fluid.aspectRatio * 100 + 24.58
                                }%`
                              : "100%",
                        }}
                      >
                        <Image
                          key={index}
                          fluid={image.asset.fluid}
                          alt={image.alt}
                          style={{height:"100%"}}
                        />
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
          <div className={classes.textGrid}>
          {!isPhone&&  <div className={classes.itemHeader}>
              <Typography align="center" variant="h2">
                {data.heading}
              </Typography>
              <Typography variant="h4" align="center">
                {data.label}
              </Typography>
            </div>
}
            <div className={classes.itemText}>
              <TextBlock text={data.text._rawText} />
            </div>
            <div className={classes.itemButton}>
              {data.cta && (
                <AthCta
                  title={data.cta.title}
                  link={data.cta.route.slug.current}
                />
              )}
            </div>
          </div>
        </div>
      </Zoom>
    </AthSection>
  )
}
