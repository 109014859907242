import React from "react"
import { AthSection, AthMap } from "../layout"
import { makeStyles, Typography } from "@material-ui/core"
import ContactForm from "./contactForm"
import Zoom from "react-reveal/Zoom"
import FacebookIcon from "@material-ui/icons/Facebook"
const useStyles = makeStyles(theme => ({
  contactContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "100%",
    gridGap: "1rem",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      padding:"0",
      height: "fit-content",
    },
  },
  mapContainer: {
    gridColumn: "1/2",
    height: "100%",
    width: "100%",
    display:'flex',
    flexDirection:'column',
    padding:theme.spacing(3)
  },
  contactDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding:theme.spacing(3),
    [theme.breakpoints.down('xs')]:{
      padding:0,
      flexDirection:"column",
      alignContent:"flex-start",
      alignItems:"flex-start",
      "& div":{
        padding:"0.5rem 0"
      }
    }
  },
  socials: {
    color: "blue",
    [theme.breakpoints.down('xs')]:{
      padding:"1rem 0"
    },
    "&>a:visited":{
      color:"blue"
    },
    "&>a>svg": {
      fontSize: "2rem",
    },
  },
  headerContainer: {
    padding: "1.5rem 0",
    marginBottom: "1.5rem",
  },
  subtext:{
    margin:"1rem 0"
  }
}))
export default function Contact({ data }) {
  const classes = useStyles()
  return (
    <AthSection id="contact">
      <Zoom>
        <div className={classes.headerContainer}>
          <Typography align="center" className={classes.heading} variant="h1">
           Contact
          </Typography>
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.mapContainer}>
            <div className={classes.contactDetails}>
              <div>
              <Typography variant="h4">229 Main Road, Lower Moutere</Typography>
              <Typography variant="h4">Phone: 03-526 7719</Typography>
              </div>
              <div>
                <Typography variant="h4">Office Hours</Typography>
                <Typography variant="h5">Mon-Fri : 7:30AM to 5PM</Typography>

              </div>
              <div className={classes.socials}>
                <a href="https://www.facebook.com/chambersandjackett/" aria-label="Facebook">
                  <FacebookIcon />
                </a>
              </div>
            </div>
            <AthMap data={data.mapString} />
          </div>
          <div className={classes.contactForm}>
            <ContactForm />
          </div>
        </div>
      </Zoom>
    </AthSection>
  )
}
