import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Image from 'gatsby-image'
import TextBlock from "../../lib/textblock"
const useStyles = makeStyles(theme => ({
  testimonialContainer: {
    display: "flex",
    flexDirection:"column",
    position: "relative",
    height:"fit-content",
    alignItems:"flex-start"
  },
  quote: {
    content: "open-quote",
    fontSize: "5rem",
    fontFamily: "Poppins",
    color: "goldenrod",
    marginBottom:"-2rem"
  },
  textbg: {
    backgroundColor: "#e2e2e2",
    textAlign: "left",
    padding: "2rem",
  },
  logo:{
    width:"20%",
    padding:"1rem",
    [theme.breakpoints.down('xs')]:{
      width:"50%"
    }
  }
}))
export default function Testimonial({ data }) {
  const classes = useStyles()
  return (
    <div className={classes.testimonialContainer}>


        <div className={classes.textbg}>
          <div className={classes.message}>
            <TextBlock
              text={data.testimonialText._rawText}
              />
          </div>
          <div className={classes.name}>
            <Typography variant="h4">{data.writerName}</Typography>
          </div>
          <div className={classes.position}>
            <Typography variant="h5">{data.writerPosition}</Typography>
          </div>
        </div>

      <div className={classes.logo}>
        <Image fluid={data.companyLogo.asset.fluid} alt={data.companyLogo.alt}/>
      </div>
    </div>
  )
}
