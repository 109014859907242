import React from "react"
import { Typography, Grid } from "@material-ui/core"
import Image from "gatsby-image"
import TextBlock from '../../lib/textblock'

export default function AthCard({ card }) {

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} >
          <Image
            fluid={card.image.asset.fluid}
            alt={card.image.alt}
          />
      </Grid>
        <Grid item  xs={12}>
          <Typography variant="h4" align="center" >
            {card.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="span">
            {card.position}
          </Typography>
        </Grid>
        <Grid item xs={12}>
        {card.message ? (
          <TextBlock text={card.message._rawText} />
        ) : (
          ""
        )}</Grid>
    </Grid>
  )
}
